@import '../../../config.scss';

.oldFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.oldForm {
  background-color: #fbfbfb;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  margin: 2rem 0;
  box-shadow: 1px 1px 15px -10px;
}

.oldFormItemContainer {
  max-width: 1000px;
  display: flex;
  margin: 16px;
  padding: 1rem;
}

.oldFormItemContainer > .ant-col {
  display: flex;
  flex: 1;
}

.oldFormItemContainer_bookings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  width: 1000px;
  max-height: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.oldFormItemContainer_bookings > h4 {
  grid-row-start: 1;
}

.oldFormItemContainer_bookings > div:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 2;
}

.oldFormItemContainer_bookings > div:nth-child(3) {
  grid-row-start: 2;
  grid-column: 2;
}

.oldFormItemContainer_election {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items: start;
  width: 1000px;
  margin: 16px 16px;
  padding: 1rem;
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 1px 1px 12px -10px;
}

.oldElectionContainer > .ant-form-item {
  width: 100%;
}

.oldElectionContainer > .ant-form-item > .ant-form-item-control {
  align-items: flex-end;
}

.oldFormItemContainer_samflot {
  display: grid;
  grid-template-columns: 50% 45% 5%;
  grid-template-rows: 1fr;
  width: 1000px;
  max-height: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.oldFormItemContainer_samflot > .ant-form-item {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .oldFormItemContainer {
    width: 600px;
    max-height: 1000px;
    margin: 16px 16px;
    padding: 1rem;
  }
}

.formContentContainer {
  margin: 0.5rem 0;
}

.oldFormItemContainer_rss {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  width: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.oldFormItemContainer_rss > h4 {
  grid-row-start: 1;
}

.oldFormItemContainer_rss > div:last-of-type {
  display: grid;
  grid-column: 2 / 2;
}

.formText {
  font-size: 1rem;
  font-weight: 700;
  color: $primary;
}

.formLabel {
  transition: 0.2s;
  font-size: 1rem;
  color: $primary;
}

.formLabel:focus {
  transition: 0.2s;
}

.textInputContainer {
  margin: 10px;
}

.btnContainer {
  text-align: center;
  width: 100%;
}

.submitBtn {
  margin: 1rem;
}

.submitBtn:hover {
  background-color: white;
}
