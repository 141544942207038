@import '../../../config.scss';

// .contentText {
//   color: rgba(0, 0, 0, 0.45);
// }

// .contentSpanText {
//   color: $primary;
//   font-weight: 500;
// }

// .situationalCard {
//   width: 445px;
//   height: 190px;
//   margin-top: 10px;
//   margin-left: 10px;
//   box-shadow: 1px 1px 15px -10px;
// }

// NÝJA CSS
.contentText {
  color: rgba(0, 0, 0, 0.45);
}

.contentSpanText {
  color: $primary;
  font-weight: 500;
}
