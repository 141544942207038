@import '../../config.scss';

.headerSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  // margin-bottom: 150px;

  @media screen and (max-width: $mediumScreen) {
    margin-bottom: 1rem;
  }
}

.headerTitle {
  font-size: 45px;
  padding-left: 30px;
  border-left: #02529c 4px solid;
  line-height: 40px;

  @media screen and (max-width: 700px) {
    font-size: 20px;
    line-height: 30px;
  }
}

// VERSION 2
// <----------------------------->
// .headerImage > img {
//   display: flex;
//   width: 100%;
//   height: 410px;
//   object-fit: cover;

//   @media screen and (max-width: $mediumScreen) {
//     height: 300px;
//   }
// }
// <----------------------------->

// VERSION 3
// -------------------------------
.headerImage {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  // background-image: url('../../images/borgartun_overlay.png');
  background-image: url('../../images/borgartun_50op.png');
  background-position: 50% 85%;
  // background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.headerTitleContainer {
  width: 1000px;

  @media screen and (max-width: 1000px) {
    width: 700px;
  }

  @media screen and (max-width: 700px) {
    width: 380px;
  }
}
// -------------------------------

// .headerTextContainer {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   width: 555px;
//   height: 215px;
//   transform: translateY(95%);
//   z-index: 10;
//   background: white;
//   margin-left: 48px;
//   padding: 48px 72px;

//   @media screen and (max-width: $mediumScreen) {
//     width: $smallScreen - 50px;
//     height: 110px;
//     transform: translateY(55%);
//   }

//   @media screen and (max-width: $smallScreen + 20px) {
//     width: 290px;
//     height: 110px;
//     margin-left: auto;
//   }
// }

// .headerText {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 14px;

//   @media screen and (max-width: $mediumScreen) {
//     font-size: 8px;
//   }

//   @media screen and (max-width: $smallScreen + 20px) {
//     font-size: 7px;
//   }
// }
