@import '../../config.scss';

.smallPieChartCard {
  height: calc(50% - 10px);
  background-color: white;
  border-radius: 15px;
  box-shadow: $box-shadow;
}

.smallPieChartContent {
  height: 100%;
  width: 100%;
  display: flex;
}

.smallPieChartContainer {
  height: 100%;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallPieChartDataContainer {
  height: 100%;
  width: 65%;
  display: flex;
  padding: 0 20px 0 20px;
  justify-content: space-between;
  flex-direction: column;
}

.smallPieChartContentTitleContainer {
  text-align: center;
}

.smallPieChartContentTitle {
  margin: 0;
  font-size: 11px;
  padding: 10px;
}

.dataLabel {
  font-size: 10px;
}
