@import '../../../config.scss';

.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.form {
  background-color: #fbfbfb;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  margin: 2rem 0;
  box-shadow: 1px 1px 15px -10px;
}

.formitemContainer {
  max-width: 1000px;
  display: flex;
  margin: 16px;
  padding: 1rem;
}

.formitemContainer > .ant-col {
  display: flex;
  flex: 1;
}

.formitemContainer_bookings {
  display: flex;
  flex-direction: row;
  width: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.commentContainer {
  width: 100%;
  padding: 16px;
  border: 1px solid rgb(226, 226, 226);
  margin-top: 10px;
}

.commentContainer > .ant-space-item {
  width: 100%;
}

.bookingsContainer {
  width: 50%;
}

.bookingsContainer > .ant-col {
  display: flex;
  flex: 1;
}

.formitemContainer_bookings > h4 {
  grid-row-start: 1;
}

.formitemContainer_election {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items: start;
  width: 1000px;
  margin: 16px 16px;
  padding: 1rem;
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 1px 1px 12px -10px;
}

.electionContainer > .ant-form-item {
  width: 100%;
}

.electionContainer > .ant-form-item > .ant-form-item-control {
  align-items: flex-end;
}

.formitemContainer_samflot {
  display: grid;
  grid-template-columns: 50% 45% 5%;
  width: 1000px;
  max-height: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.formitemContainer_samflot > .ant-form-item {
  width: 100%;
}

.formitemContainer_samflot > .joint {
  grid-column-start: 2;
}

.formitemContainer_dates {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  max-height: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.oldFormItemContainer_history {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 0fr 1fr;
  width: 1000px;
  max-height: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.oldFormItemContainer_history > div:last-of-type {
  display: grid;
  grid-column: 2 / 2;
}

@media only screen and (max-width: 768px) {
  .formitemContainer {
    width: 600px;
    max-height: 1000px;
    margin: 16px 16px;
    padding: 1rem;
  }
}

.formContentContainer {
  margin: 0.5rem 0;
}

.formitemContainer_rss {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
  width: 1000px;
  margin: 16px 16px;
  padding: 1rem;
}

.formitemContainer_rss > h4 {
  grid-row-start: 1;
}

.formitemContainer_rss > div:last-of-type {
  display: grid;
  grid-column: 2 / 2;
}

.formText {
  font-size: 1rem;
  font-weight: 700;
  color: $primary;
}

.formLabel {
  transition: 0.2s;
  font-size: 1rem;
  color: $primary;
}

.formLabel:focus {
  transition: 0.2s;
}

.textInputContainer {
  margin: 10px;
}

.btnContainer {
  text-align: center;
  width: 100%;
}

.submitBtn {
  margin: 1rem;
}

.submitBtn:hover {
  background-color: white;
}
