.connectionContainer {
  margin: 0 0 100px 0;
  min-height: 700px;
}

.dragItem {
  padding: 10px;

  // &:hover {
  //   background-color: rgba(24, 144, 255, 0.05);
  // }
}

.currentContract {
  background-color: rgba(24, 144, 255, 0.05);
}

.contractAdded {
  background-color: rgba(217, 255, 0, 0.26);
}

.rowItemContainer {
  width: 100%;
  padding: 4px 16px;
  border-bottom: solid 1px #eee;
}

.rowItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.rowItemContentContainer {
  flex: 1 1;
}

.rowItemLoading {
  filter: blur(2px);
}
