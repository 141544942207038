.registerSection {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.registerContainer {
  box-shadow: 1px 1px 15px -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 470px;
  background-color: white;
}

.registerForm {
  width: 100%;
}

.registerItem {
  display: flex;
  margin: 16px;
  padding: 1rem;
}

.registerBtn {
  width: 100%;
  margin-top: 8px;
}
