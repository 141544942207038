.contractsSection {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.contractsContainer {
  margin-bottom: 2rem;
  width: 1000px;
}

.contractsContainer > :nth-child(2) {
  width: 100%;
  box-shadow: 1px 1px 15px -10px;
  background-color: white;
  padding: 16px;
}
