@import '../../../config.scss';

.TempClass {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

h1 {
  font-size: 80px;
  font-weight: 500;
} /* REMOVE WHEN STATS PAGE IS DONE */

.statsPage {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundColor;
}

.statsTopSection {
  width: 80%;
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 20px;
}

.statsMainCard {
  // border-radius: max(0px, min(16px, (100% - 400px + 1px) * 9999)) / 16px;
  width: 100%;
  height: 380px;
  background-color: white;
  border-radius: 15px;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.mainCardContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  // align-items: center;
}

.mainCardTitleContainer {
  height: 50px;
  text-align: center;
  padding: 10px;
}

.mainCardTitle {
  margin: 0;
  font-size: 20px;
}

.mainCardChartContainer {
  // height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1500px) {
    height: 16vw;
  }
}

.mainCardContentContainer {
  // height: 20vw;
  height: 100%;
  width: 50%;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mainChartLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mainChartLabelText {
  margin: 0;
  font-size: 16px;
}

.mainChartLabelDot {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

.mainCardDataContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mainCardSelectionContainer {
  border-top: solid black 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.statsPieChartsCards {
  width: 100%;
  height: 380px;
}

.statsPieChartsCards > :last-child {
  margin-top: 20px;
}

.chartNumberData {
  font-weight: 700;
  font-size: 16px;
}

.statsTable {
  margin: 20px;
}

.statsTable {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  background-color: white;
  box-shadow: $box-shadow;

  .ant-tabs-nav {
    margin: 0;
  }
}

.chartDisclaimer{
  margin: 20px;
  color: gray;

  @media screen and (max-width: 1750px) {
    font-size: 12px;
  }
}