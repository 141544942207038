// .adminContentSection {
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   margin: 2rem 0;
// }

.adminGoBack {
  align-content: flex-start;
}

.adminGoBackContainer {
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1000px) {
    width: 700px;
  }

  @media screen and (max-width: 700px) {
    width: 380px;
  }
}

// .adminContentContainer {
//   width: 1000px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: center;
//   margin-bottom: 40px;
// }

// .adminTwocardContainerLeft {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
// }

// .adminTwocardContainerRight {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
// }

// .adminBtnContainer {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// }
