@import '../../config.scss';

.statsSection {
  width: 100%;
  min-height: 800px;
  background-color: white;
}

.statsContainer {
  width: 80%;
  margin: auto;
}

.disclaimerContainer {
  width: 50%;
}

.frontPageImgContainer {
  height: 600px;
}

.frontPageImg > img {
  display: flex;
  position: absolute;
  width: 100%;
  // height: 600px;
  object-fit: cover;
}

// FRONTPAGE NR 2

.hero {
  width: 100%;
  //   background-color: white;
  background-color: '#f6f6f6';
  // height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  // EF FIXED ÞÁ ÞESSI KÓÐI
  // height: 55%;
  // padding-top: 20px;
}

.heroContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-bottom: 30px;
  max-width: 1000px;

  @media screen and (max-width: 1000px) {
    margin-bottom: none;
  }
}

.heroTitle {
  font-size: 50px;
  font-family: 'Crimson Pro', serif;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 80px;
}

.heroSubTitle {
  font-size: 20px;
  font-family: 'Crimson Pro', serif;
}

.heroInputContainer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 70%;
}

.heroButtons {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.heroDisclaimer {
  width: 70%;
  display: flex;
  flex-direction: column;
  // margin-top: 40px;
  margin-right: 20px;
  justify-content: center;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-right: 0;
  }
}

.disclaimer {
  width: 100%;
  justify-self: center;
  font-size: 18px;
  font-family: 'Crimson Pro', serif;
  margin: 10px 0;
  color: rgb(58, 58, 58);

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    align-self: center;
  }
}

.disclaimer:last-of-type {
  margin: 0;
}

.charts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
