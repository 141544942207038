@import '../../config.scss';

.bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: transparent;
  transition: 0.5s;
  background-color: #f6f6f6;

  // FIXED HÉR AÐ NEÐAN
  // position: fixed;
  // width: 100%;
  // z-index: 999;
}

.navbarLogo {
  // background-image: url('../../images/navlogo.png');
  height: 90px;
  object-fit: cover;
  display: block;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.logoMobile {
  // background-image: url('../../images/rsslogo.png');
  height: 90px;
  object-fit: cover;

  display: none;

  @media screen and (max-width: 700px) {
    display: block;
  }
}

// .barScrolled {
//   background-color: white;
//   transition: 0.5s;
// }

.navLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navLogo {
  // height: 100%;
  height: 90px;
  object-fit: cover;
}

.navTitle {
  font-family: monospace;
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.loginBtn {
  margin-right: 1rem;
}

.navLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navLink {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  margin: 0 10px;
  color: rgb(102, 102, 102);
  font-family: monospace;
}

.navLink:hover {
  color: $primary;
}

// // VOL 2
// .bar {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   height: 80px;
//   width: 100%;
//   background-color: white;
//   position: fixed;
//   z-index: 999;
// }

// .navLogoContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// }

// .navLogo {
//   height: 100%;
//   width: 80px;
// }

// .navTitle {
//   font-family: monospace;
//   margin-left: 0.5rem;
//   margin-bottom: 0;
// }

// .loginBtn {
//   margin-right: 1rem;
// }

// .navLinksContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// }

// .navLink {
//   display: flex;
//   align-items: center;
//   border-radius: 10px;
//   padding: 10px;
//   height: 50px;
//   margin: 0 10px;
//   color: rgb(102, 102, 102);
//   font-family: monospace;
// }

// .navLink:hover {
//   color: #1890ff;
// }
