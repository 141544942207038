// .timelineCard {
//   width: 445px;
//   height: 400px;
//   margin-left: 10px;
//   margin-bottom: 10px;
//   overflow: hidden;
// }

// .timelineCard > .ant-card-body {
//   height: 100%;
//   overflow: scroll;
// }

// NÝJA CSS
.timelineCard {
  overflow: hidden;
  max-height: 400px;
}

.timelineCard > .ant-card-body {
  height: 100%;
  overflow: scroll;
}
