// @import '../../config.scss';

// .pieChartCard {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   background-color: white;
//   box-shadow: $box-shadow;
//   border-radius: 20px;
//   // height: 80%;
//   // min-height: 350px;
//   // max-height: 440px;
//   align-self: center;
//   margin-left: 30px;
// }

// .pieChartDisplayNone {
//   display: block;
//   @media screen and (max-width: 700px) {
//     display: none;
//   }
// }

// .pieChartContainer {
//   margin: 10px;
//   height: 100%;
//   padding: 16px;
//   text-align: center;
// }

// .monthSelection {
//   width: 120px;

//   @media screen and (max-width: 1350px) {
//     width: 80px;
//   }

//   @media screen and (max-width: 900px) {
//     display: none;
//   }
// }

// .pieChartTitle {
//   @media screen and (max-width: 1000px) {
//     font-size: 14px;
//   }
// }

// .pieChartContent {
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   justify-content: space-between;
// }

// .pieChartCount {
//   @media screen and (max-width: 1000px) {
//     font-size: 12px;
//   }
// }

// TILRAUN TIL AÐ CSS-A ÞENNAN VIÐBJÓÐ
// @import '../../config.scss';

// .pieChartCard {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: white;
//   box-shadow: $box-shadow;
//   border-radius: 20px;
//   align-self: center;
//   height: 100%;
// }

// .pieChartDisplayNone {
//   display: block;
//   @media screen and (max-width: 700px) {
//     display: none;
//   }
// }

// .pieChartContainer {
//   height: 90%;
//   width: 100%;
//   padding: 20px;
//   text-align: center;
// }

// .monthSelection {
//   width: 120px;

//   @media screen and (max-width: 1350px) {
//     width: 80px;
//   }

//   @media screen and (max-width: 900px) {
//     display: none;
//   }
// }

// .pieChartTitle {
//   @media screen and (max-width: 1000px) {
//     font-size: 14px;
//   }
// }

// .pieChartContent {
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   justify-content: space-between;
// }

// // .pieChartCount {
// //   @media screen and (max-width: 1000px) {
// //     font-size: 12px;
// //   }
// // }

// TILRAUN NUMER 2 TIL AÐ CSS-A ÞENNAN VIÐBJÓÐ
@import '../../config.scss';

.pieChartCard {
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 20px;
  align-self: center;
  height: 100%;
}

.pieChartTitleContainer {
  padding: 20px 20px 0 20px;
  text-align: center;
}

.pieChartTitle {
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
}

.pieChartDisplayNone {
  display: block;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.pieChartContainer {
  // padding: 20px;
  // text-align: center;
  height: 250px;
  // width: 100%;

  // height: 16vw;
  // width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthSelection {
  width: 120px;

  @media screen and (max-width: 1350px) {
    width: 80px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.pieChartContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
}

.pieChartCount {
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
}
