/* li:last-of-type {
  padding: 0;
} */

.timelineItemHeildar {
  padding-bottom: 40px;
}

.timelineItemKjara {
  padding-bottom: 80px;
}
