/* .bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #f6f6f6;
}

.navLogoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navLogo {
  height: 70%;
  width: 80px;
}

.navTitle {
  font-family: monospace;
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.loginBtn {
  margin-right: 1rem;
}

.navLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

.logOutBtnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3px 10px;
}

.logOutBtnContainer:hover {
  background-color: white;
  cursor: default;
}
