.filteredSearchForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.leftFilterContainer {
  width: 60%;
}

.rightFilterContainer {
  width: 40%;
}

.unionsEmployersContainer {
  display: flex;
  flex-direction: row;
}

.unionsSelection {
  width: 35%;
  margin-right: 3rem;
}

.employersSelection {
  width: 35%;
}

.filterFormItem {
  display: flex;
  flex-direction: row;
}
