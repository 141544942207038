@import './config.scss';
@import './theme.css';

p {
  margin: 0;
}

.mainSection {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  border-left: 0;
  height: 100vh;
}

.mainContainer {
  max-width: $normalScreen;
  width: calc(100% - 40px);

  @media screen and (max-width: $mediumScreen) {
    max-width: calc(700px - 50px);
  }

  @media screen and (max-width: $smallScreen) {
    max-width: calc(510px - 50px);
  }
}

.mainContent {
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 5px;
  margin: 0;
}

.mainContent > .ant-tabs-nav {
  border-bottom: solid 2px #eee;
  margin-bottom: 0 !important;
}

.mainContent__contractlist {
  max-width: $normalScreen;
  margin-bottom: 2rem;
  width: calc(100% - 40px);

  @media screen and (max-width: $mediumScreen) {
    max-width: calc(700px - 50px);
  }

  @media screen and (max-width: $smallScreen) {
    max-width: calc(510px - 50px);
  }
}

.paddingFix > :nth-child(2) {
  padding: 1rem;
}

.mainContent__contractlist > :nth-child(2) {
  width: 100%;
  box-shadow: $box-shadow;
  background-color: white;
  padding: 1rem;
}

.p-lr16 {
  padding: 0 16px !important;
}

.p-tb16 {
  padding: 16px 0;
}

.p-b16 {
  padding-bottom: 16px !important;
}

.p-t16 {
  padding-top: 16px;
}

.m-b16 {
  margin-bottom: 16px;
}

.m-t16 {
  margin-top: 16px;
}

.m0 {
  margin: 0;
}

.bg_white {
  background-color: white;
}

.bs {
  box-shadow: 1px 1px 15px -10px;
}
