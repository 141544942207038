// .contentSection {
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   margin: 2rem 0;
// }

// .goBackContainer {
//   width: 1000px;
// }

// .contentSection > .goBackContainer {
//   display: flex;
//   align-items: flex-start;
// }

// .goBack {
//   align-content: flex-start;
// }

// .contentContainer {
//   width: 1000px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: center;
//   margin-bottom: 40px;
// }

// .twocardContainerLeft {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
// }

// .twocardContainerRight {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
// }

// .btnContainer {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// }

// NÝTT CSS GLÆNÝTT JIBBÍ

.contentSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.goBackContainer {
  width: 1000px;

  @media screen and (max-width: 1000px) {
    width: 700px;
  }

  @media screen and (max-width: 700px) {
    width: 380px;
  }
}

.contentContainer {
  width: 1000px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;

  @media screen and (max-width: 1000px) {
    width: 700px;
  }

  @media screen and (max-width: 700px) {
    width: 380px;
  }
}

.contractPageCards {
  width: 100%;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

// .contractPageCard {
//   box-shadow: 1px 1px 15px -10px;
//   overflow: hidden;
// }
