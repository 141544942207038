@import '../../config.scss';

.preferencesBarContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  gap: 16px;

  @media screen and (max-width: $mediumScreen) {
    flex-wrap: wrap;
  }
}
