@import '../../../config.scss';

// .contentText {
//   color: rgba(0, 0, 0, 0.45);
// }

// .contentSpanText {
//   color: #1890ff;
//   font-weight: 500;
// }

// .infoCard {
//   width: 535px;
//   min-height: 400px;
//   margin-top: 10px;
//   margin-right: 10px;
//   overflow: hidden;
// }

// NÝJA CSS
.contentText {
  color: rgba(0, 0, 0, 0.45);
}

.contentSpanText {
  color: #1890ff;
  font-weight: 500;
}

.infoCard {
  overflow: hidden;
}
