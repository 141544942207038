.notfound_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100vw;
  padding-top: 5rem;
}

.notfound_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
}

.notfound_title {
  font-weight: 700;
  font-size: 50px;
  border-bottom: solid 3px gray;
}

.notfound_img {
  height: 400px;
  width: 500px;
}

.notfound-btn {
  margin-top: 1.5rem;
}
