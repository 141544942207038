// .chartCard {
//   width: 535px;
//   max-height: 400px;
//   margin-bottom: 10px;
//   margin-right: 10px;
//   overflow: hidden;
// }

// .chartCard > .ant-card-body {
//   border-top: 1px solid #f0f0f0;
//   height: 100%;
//   overflow: scroll;
// }

// .chartCard > .ant-card-head {
//   border-collapse: separate;
//   border-spacing: 0px;
//   z-index: 999;
// }

// .loadingIcon {
//   display: flex;
//   justify-content: center;
// }

// .stats {
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// NÝJA CSS
.chartCard {
  max-height: 400px;
  overflow: hidden;
}

.chartCard > .ant-card-body {
  border-top: 1px solid #f0f0f0;
  height: 100%;
  overflow: scroll;
}

.chartCard > .ant-card-head {
  border-collapse: separate;
  border-spacing: 0px;
  z-index: 999;
}

.loadingIcon {
  display: flex;
  justify-content: center;
}

.stats {
  overflow: hidden;
  text-overflow: ellipsis;
}
