@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap');

$normalScreen: 1000px;
$mediumScreen: 700px;
$smallScreen: 510px;

$backgroundColor: #f6f6f6;
$borderColor: #eee;
$primary: #02529c;

$box-shadow: 1px 1px 15px -10px;

/*Returns a CSS clamp function which resolves to $size-at-min-width when page width is 
below $min-width, 
$size-at-max-width when page width is above $max-width, 
and linearly scales between the two between $min-width and $max-width*/

@function clamp-calc($min-width, $max-width, $size-at-min-width, $size-at-max-width) {
    $slope: ($size-at-max-width - $size-at-min-width) / ($max-width - $min-width);
    $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
    $return-value: clamp(#{$size-at-min-width}, #{$y-axis-intersection} + #{$slope} * 100vw, #{$size-at-max-width});
    @return $return-value;
}
