.progressContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.progressBar {
  margin-bottom: 16px;
}
