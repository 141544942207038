.loginSection {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.loginContainer {
  box-shadow: 1px 1px 15px -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 400px;
  background-color: white;
}

.loginForm {
  width: 100%;
}

.loginItem {
  display: flex;
  margin: 16px;
  padding: 1rem;
}

.loginBtn {
  width: 100%;
  margin-top: 8px;
}
